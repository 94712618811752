import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import WhyUs from "../components/Index/WhyUs"
import Banner2 from "../components/Index/Banner2"
import OurDifference from "../components/Index/OurDifference"
import Rates from "../components/Index/Rates"
import Lender from "../components/Index/Lender"
import CTABanner from "../components/Common/CTABanner"
import TopCalculators from "../components/Common/TopCalculators"
import SEO from "../components/App/SEO"
import Partners from "../components/Index/Partners"

const Home = () => {
  return (
    <Layout>
      <SEO />
      <Navbar />
      <Banner2/>
      <WhyUs/>
      {/* <Banner /> */}
      {/* <Rates /> */}
      <Lender />
      <OurDifference />
      <div className="pt-5 pb-70">
        <TopCalculators sectionTitle="Calculators" showMore="true" />
      </div>
      <Partners/>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default Home

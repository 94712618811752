
import React from "react"
import ReactWOW from "react-wow"
import MiniContactForm from "./MiniContactFormRedesign"
import {useStaticQuery, graphql } from "gatsby"
import Badges from "../App/Badge"
import { Link } from "gatsby"



const Banner = () => {

  const responseData = useStaticQuery(graphql`
      query GetOORatesQuery2 {
        allStrapiRateCards(filter: {Rate_Name: {in: ["Interest_Rate_OO", "LVR_OO", "Comparison_Rate_OO"]}}) {
          edges {
            node {
              Rate_Name
              Rate_Value
            }
          }
        }
      }
    `);

  const interestRateOO = responseData.allStrapiRateCards.edges.find(edge => edge.node.Rate_Name === "Interest_Rate_OO").node.Rate_Value || "N/A";
  const comparisonRateOO = responseData.allStrapiRateCards.edges.find(edge => edge.node.Rate_Name === "Comparison_Rate_OO").node.Rate_Value || "N/A";
  const lvrOO = responseData.allStrapiRateCards.edges.find(edge => edge.node.Rate_Name === "LVR_OO").node.Rate_Value || "N/A";
  
  return (
    <div className="home-hero-banner-revamp">
      <div className="container hero">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-12 my-5">
            <div className="hero-title-wrapper mt-5">
              {/* <ReactWOW delay=".5s" animation="fadeInDown"> */}
                <div className="chevron-up"></div>
              {/* </ReactWOW> */}
              <h1 className="hero-title">Home Loans <span className="">Made Simple </span><ReactWOW delay=".5s" animation="fadeInDown">
                <sup className=""> TM</sup>
              </ReactWOW></h1>         
            </div>
            <h2 className="mb-4">Find a better home loan in minutes.</h2>
          </div>          
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-6">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                  <ReactWOW delay=".05s" animation="fadeInUp">
                    {/* <h2 className="low-rate-title mt-4 mb-2">Low Rate SMSF Loans​</h2> */}
                    <div className="d-flex justify-content-center h-100">
                      <div className="rates-comparison">
                        <div className="">
                          <div className="green-corona"></div>
                          <div className="row">
                            <div className="col-lg-12 col-6">
                              <div className="rates-group mb-lg-3 mt-lg-4">
                              <label>Rates from</label>
                                <div className="rate-wrapper">
                                  <h2 className="rate">{interestRateOO}</h2>
                                  <div className="percentage">
                                    <p className="percentage-symbol d-flex flex-column">
                                      %<span>p.a</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 col-6">
                              <div className="rates-comparison-group mb-lg-3">
                                <label>Comparison</label>
                                <div className="rate-wrapper">
                                  <h2 className="rate">{comparisonRateOO}</h2>
                                  <div className="percentage">
                                    <p className="percentage-symbol d-flex flex-column">
                                      %<span>p.a</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
    
                        </div>
                        <div className="owner-occupied mb-4">
                          <p>Owner occupied | P&I | {lvrOO}% LVR </p>
                        </div>
                        
                        
                          <div className="btn-box">
                            <Link
                              to="/book-a-call"                              
                              className="default-btn mid-width-btn primary-btn btn-lg mb-2 mr-sm-3 mr-lg-2"
                              rel="noopener noreferrer"
                            >
                              Talk to an Expert<span></span>
                            </Link>
                            {/* {`${process.env.GATSBY_ENVIRONMENT}` === "staging" ? (
                              <a
                                href={`${process.env.GATSBY_OLAP_BASE_URL}/?SourceType=OxygenWebSite`}
                                className="default-btn mid-width-btn primary-btn btn-lg mb-2 mr-sm-3 mr-lg-2"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Talk to an Expert<span></span>
                              </a>
                            ) : (
                              <a
                                href={`${process.env.GATSBY_OLAP_BASE_URL}/?SourceType=OxygenWebSite`}
                                className="default-btn mid-width-btn primary-btn btn-lg mb-2 mr-sm-3 mr-lg-2"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Talk to an Expert<span></span>
                              </a>
                            )} */}
                          </div>
                        
                      </div>
                    </div>
                    {/* <p className="low-rate-description mt-3">​​​Oxygen has some of the lowest rate SMSF loans in the market, whether you want to refinance an existing loan or purchase a new property. Check out our range of low rate SMSF loans and start saving thousands today!</p> */}
                  </ReactWOW>
              </div>
              <div className="col-lg-5 col-md-12 mt-lg-0 mt-4">
                <ReactWOW delay=".05s" animation="fadeInUp">
                  <div className="row">
                    <div className="col-lg-12 col-md-6 col-6 mb-lg-4">
                      <Link
                        to="/calculators"
                      >
                        <div className="calculator-tile">
                          <div className="white-puncture-bottom"></div>
                          <h5>Try Calculators</h5>
                          <div className="icon-calculator"></div>
                        </div>
                      </Link>
                    </div>

                    <div className="col-lg-12 col-md-6 col-6">
                      <Link
                        to="/about-us"
                      >
                      <div className="home-tile h-100">
                        <div className="white-puncture"></div>
                        <h5>About Us</h5>
                        <div className="icon-home"></div>
                      </div>
                      </Link>
                    </div>
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-md-0 mt-4">         
              <MiniContactForm />
          </div>
        </div>
      </div>

    </div>
  )
}

export default Banner

import React from "react"
import ReactWOW from "react-wow"
import MiniContactForm from "./MiniContactFormRedesign"
import Badges from "../App/Badge"
import { Link } from "gatsby"

import ach1 from "../../assets/images/about/01.png"
import ach2 from "../../assets/images/about/02.png"
import ach3 from "../../assets/images/about/03.jpg"
import ach4 from "../../assets/images/about/04.jpg"
import ach5 from "../../assets/images/about/05.png"
import ach6 from "../../assets/images/about/06.jpg"
import ach7 from "../../assets/images/about/07.jpg"
import ach8 from "../../assets/images/about/08.jpg"
import ach9 from "../../assets/images/about/09.jpg"
import ach10 from "../../assets/images/about/10.jpg"
import ach11 from "../../assets/images/about/11.jpg"
import ach12 from "../../assets/images/about/12.jpg"
import ach13 from "../../assets/images/about/13.jpg"
import ach14 from "../../assets/images/about/14.jpg"
import ach15 from "../../assets/images/about/15.jpg"
import ach16 from "../../assets/images/about/16.jpg"
import ach17 from "../../assets/images/about/17.jpg"
import ach18 from "../../assets/images/about/18.jpg"
import ach19 from "../../assets/images/about/19.jpg"
import ach20 from "../../assets/images/about/20.jpg"
import ach21 from "../../assets/images/about/21.jpg"
import ach22 from "../../assets/images/about/22.jpg"
import ach23 from "../../assets/images/about/23.jpg"
import ach24 from "../../assets/images/about/24.jpg"
import ach25 from "../../assets/images/about/25.jpg"
import ach26 from "../../assets/images/about/26.jpg"
import ach27 from "../../assets/images/about/27.jpg"
import ach28 from "../../assets/images/about/28.jpg"
import ach29 from "../../assets/images/about/29.jpg"
import ach30 from "../../assets/images/about/30.jpg"
import ach31 from "../../assets/images/about/31.jpg"
import ach32 from "../../assets/images/about/32.jpg"
import ach33 from "../../assets/images/about/33.jpg"
import ach34 from "../../assets/images/about/34.jpg"
import ach35 from "../../assets/images/about/35.jpg"
import ach36 from "../../assets/images/about/36.jpg"
import ach37 from "../../assets/images/about/37.jpg"
import ach38 from "../../assets/images/about/38.jpg"
import ach39 from "../../assets/images/about/39.jpg"
import ach40 from "../../assets/images/about/40.jpg"
import ach41 from "../../assets/images/about/img-finalist-aba-award-20222.png"
import ach42 from "../../assets/images/abm-badge.png"
import ach43 from "../../assets/images/about/AwardFinal.png"

// import Loadable from "@loadable/component"
// export const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

// export const options = {
//   loop: true,
//   nav: false,
//   dots: false,
//   autoplayHoverPause: true,
//   autoplay: true,
//   margin: 30,
//   navText: [
//     "<i class='flaticon-left-1'></i>",
//     "<i class='flaticon-right-1'></i>",
//   ],
//   responsive: {
//     0: {
//       items: 3,
//     },
//     768: {
//       items: 4,
//     },
//     992: {
//       items: 5,
//     },
//   },
// }



const WhyUs = () => {
  
  return (
    <div className="why-us-section container ptb-100">
      <h2 className="">Why us<span>?</span></h2>
      <ReactWOW delay=".05s" animation="fadeInUp">
      <div className="row justify-content-center text-center">

        {/* Multi-award winning */}
        <div className="col-md-6 col-12 d-flex flex-column align-items-center my-3">
          <div className="pill-tile d-flex justify-content-center align-items-center p-4 mt-lg-2">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-3 col-12">
              <div className="award-caption">Multi-award winning</div>
              </div>
              <div className="col-lg-9 col-12">
                <div className="d-flex mx-3">
                <Badges />
                </div>
              </div>
            </div>
          </div>
          <p className="circle-tile-text mt-lg-4 mt-3">And many more awards</p>
        </div>

        {/* Stats Section */}
        <div className="col-lg-2 col-md-3 col-md-3 col-6 my-3">
          <div className="circle-tile p-4">
            <h3 className="">$12+</h3>
            <h4>Billion</h4>
          </div>
          <p className="mt-3 circle-tile-text">in home loans settled</p>
        </div>

        <div className="col-lg-2 col-md-3 col-6 my-3">
          <div className="circle-tile p-4">
            <h3 className="">22</h3>
            <h4>Years</h4>
          </div>
          <p className="mt-3 circle-tile-text">of service and counting</p>
        </div>

        <div className="col-lg-2 col-md-3 col-6 my-3">
          <div className="circle-tile p-4">
            <h3 className="">100+</h3>
            <h4>Locations</h4>
          </div>
          <p className="mt-3 circle-tile-text">across Australia!</p>
        </div>

        {/* </div>

        <div className="row justify-content-center text-center"> */}

        <div className="col-lg-2 col-md-3 col-6 my-3">
          <div className="circle-tile-2 p-4">
            <h3 className="">$3.5+</h3>
            <h4>Billion</h4>
          </div>
          <p className="mt-3 circle-tile-text">Loan book</p>
        </div>

        <div className="col-lg-2 col-md-3 col-6 my-3">
          <div className="circle-tile-2 p-4">
            <h3 className="">8x</h3>
            <h4>Winner</h4>
          </div>
          <p className="mt-3 circle-tile-text">of regional realtor award</p>
        </div>

        <div className="col-lg-2 col-md-3 col-6 my-3">
          <div className="circle-tile-2 p-4">
            <h3 className="">35</h3>
            <h4>Thousand</h4>
          </div>
          <p className="mt-3 circle-tile-text">customers served</p>
        </div>

        {/* Feature List */}
        <div className="col-lg-6 col-md-10 col-12 my-3">
          <div className="pill-tile-2 d-flex justify-content-center align-items-center p-4 mt-lg-2">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12 col-sm-3 d-flex justify-content-center mb-3 mb-sm-0">
                <div className="guaranteed-icon"></div>
              </div>
              <div className="col-12 col-sm-9 mb-4 mb-sm-0">
                <ul className="m-0">
                  <li className="">Australia-wide coverage</li>
                  <li className="">Apply in minutes</li>
                  <li className="">No hidden fees</li>
                </ul>
              </div>
            </div>
          </div>
          <p className="circle-tile-text mt-3">And many more awards</p>
        </div>

        </div>
      </ReactWOW>
    </div>


  )
}

export default WhyUs